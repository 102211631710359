import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../PuzzleLibComponents.module.scss'

class AlgBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
  }

  render() {
    const { config } = this.props
    return (
      <div className={style.innerBlock}>
        <h2
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: config.descr,
          }}
        />
        <ul className={style.list}>
          {config.list.map((x, i) => (
            <li key={`item-${i}`} dangerouslySetInnerHTML={{ __html: x }} />
          ))}
        </ul>
      </div>
    )
  }
}

export default injectIntl(AlgBlock)
