import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Transition } from 'react-transition-group'

import style from './BasicList.module.scss'

const duration = 150

const listDefaultStyle = {
  transition: `opacity ${duration}ms ease-in-out, max-height ${duration}ms ease-in-out`,
  marginBottom: 24,
  padding: '48px 48px 48px 48px',
  borderRadius: '0 0 5px 5px',
  margin: 0,
  opacity: 0,
  maxHeight: 0,
  willChange: 'scroll-position, opacity, max-height',
  backgroundColor: '#fafafa',
}

const transitionStyles = {
  entering: { opacity: 0, maxHeight: 1000 },
  entered: { opacity: 1, maxHeight: 1000 },
}

class BasicList extends PureComponent {
  state = { showList: this.props.showList }
  static propTypes = {
    config: PropTypes.object,
    showList: PropTypes.bool,
  }

  toggleList = () => {
    this.setState(prevState => ({ showList: !prevState.showList }))
  }

  render() {
    const { config } = this.props
    const { showList } = this.state
    return (
      <React.Fragment>
        <div
          onClick={this.toggleList}
          className={classnames(
            style.listHeading,
            showList ? style.listShown : null
          )}
        >
          <h4 dangerouslySetInnerHTML={{ __html: config.name }} />
          <FontAwesomeIcon
            className={showList ? style.btnOpend : ''}
            icon={['fas', 'chevron-down']}
          />
        </div>
        <Transition in={showList} timeout={duration} appear unmountOnExit>
          {status => (
            <ul
              style={{
                ...listDefaultStyle,
                ...transitionStyles[status],
              }}
            >
              {config.list.map((x, i) => (
                <li key={`item-${i}`} dangerouslySetInnerHTML={{ __html: x }} />
              ))}
            </ul>
          )}
        </Transition>
      </React.Fragment>
    )
  }
}

export default BasicList
