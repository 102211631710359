import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import DefaultLayout from '../layouts/default/defaultLayout'
import PuzzleLibComponents from '../layouts/puzzlelib/PuzzleLibComponents'
import { withIntl } from '../i18n'

class PuzzleLibPage extends React.Component {
  state = { title: this.props.intl.messages.puzzleLibPage.title }

  static propTypes = {
    data: PropTypes.object,
    intl: PropTypes.object,
  }

  render() {
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={this.state.title}
        />
        <PuzzleLibComponents images={this.props.data.PuzzleLibImgs.edges} />
      </DefaultLayout>
    )
  }
}

export const query = graphql`
  query allPuzzleLibPageImgsQuery {
    PuzzleLibImgs: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativeDirectory: { regex: "/products/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 480) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`

export default withIntl(injectIntl(PuzzleLibPage))
