import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../PuzzleLibComponents.module.scss'
import { graphql, StaticQuery } from 'gatsby'

class DocumentsBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
  }

  render() {
    const { config } = this.props
    return (
      <StaticQuery
        query={graphql`
        query {
          pdf: allFile(filter: { extension: { eq: "pdf" } }) {
            edges {
              node {
                name
                publicURL
              }
            }
          }
        }
      `}
        render={(data) => {
          const documents = data?.pdf?.edges || []
          return <div className={style.innerBlock}>
            <h2
              dangerouslySetInnerHTML={{
                __html: config.heading,
              }}
            />
            <ul className={style.list}>
              {config.documents.map((x, i) => {
                const doc = documents.find(el => el.node.name === x.id)
                if (!doc) return null
                return (
                  <li key={`item-${i}`}>
                    <a download={true} target="_blank" rel="noopener noreferrer" href={doc.node.publicURL}>
                      {x.title}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        }}
      />
    )
  }
}

export default injectIntl(DocumentsBlock)
