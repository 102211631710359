import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from '../PuzzleLibComponents.module.scss'
import BasicList from '../../../components/lists/BasicList'

class ModulesBlock extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
  }

  render() {
    const { config } = this.props
    return (
      <div className={style.innerBlock}>
        <h2
          dangerouslySetInnerHTML={{
            __html: config.heading,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: config.descr,
          }}
        />
        <div className={style.listContainer}>
          {config.modules.map((x, i) => (
            <BasicList
              key={`list-${i}`}
              config={x}
              showList={i === 0 ? true : false}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default injectIntl(ModulesBlock)
