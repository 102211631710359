import React from 'react'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import style from './PuzzleLibComponents.module.scss'
import DescriptionBlock from './components/DescriptionBlock'
import AdvantageBlock from './components/AdvantageBlock'
import ReqBlock from './components/ReqBlock'
import AlgBlock from './components/AlgBlock'
import ModulesBlock from './components/ModulesBlock'
import DocumentsBlock from './components/DocumentsBlock'

class PuzzleLibComponents extends React.Component {
  state = { intl: this.props.intl.messages.puzzleLibPage }

  static propTypes = {
    images: PropTypes.array,
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.state
    return (
      <React.Fragment>
        <div className={classnames(style.basicBlock)}>
          <h1 dangerouslySetInnerHTML={{ __html: intl.heading }} />
          <DescriptionBlock config={intl.descrBlock} />
          <ReqBlock config={intl.reqBlock} />
          <AdvantageBlock config={intl.advantageBlock} />
          <ModulesBlock config={intl.modulesBlock} />
          <AlgBlock config={intl.algBlock} />
          <DocumentsBlock config={intl.documentsBlock} />
        </div>
        {/*BG STYLE*/}
        {/*         <div className={style.bg} />
        <div className={style.bg1} />
        <div className={style.bg2} />
        <div className={style.bg3} /> */}
        {/*BG STYLE END*/}
      </React.Fragment>
    )
  }
}

export default injectIntl(PuzzleLibComponents)
